import * as React from "react"
import Seo from "../components/seo"

let FlatsPage;

export const Head = () => <Seo title="Ponuka bytov" />

export default FlatsPage = () => {
    React.useEffect(() => {
        window.location.href='/';
    });
}
